<template>
  <div class="animationOpacity" :class="{'spaceAssinatura':!isMobile, 'spaceAssinaturaMobile':isMobile}">
    <div class="headerFlex">
      <div class="title">
        Assinatura
        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 10H8C8.26522 10 8.51957 9.89464 8.70711 9.70711C8.89464 9.51957 9 9.26522 9 9C9 8.73478 8.89464 8.48043 8.70711 8.29289C8.51957 8.10536 8.26522 8 8 8H5C4.73478 8 4.48043 8.10536 4.29289 8.29289C4.10536 8.48043 4 8.73478 4 9C4 9.26522 4.10536 9.51957 4.29289 9.70711C4.48043 9.89464 4.73478 10 5 10ZM17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V12C0 12.7956 0.316071 13.5587 0.87868 14.1213C1.44129 14.6839 2.20435 15 3 15H17C17.7956 15 18.5587 14.6839 19.1213 14.1213C19.6839 13.5587 20 12.7956 20 12V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM18 12C18 12.2652 17.8946 12.5196 17.7071 12.7071C17.5196 12.8946 17.2652 13 17 13H3C2.73478 13 2.48043 12.8946 2.29289 12.7071C2.10536 12.5196 2 12.2652 2 12V6H18V12ZM18 4H2V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V4Z" fill="#333333"/>
        </svg>
      </div>
    </div>
    <div class="spaceSearch">
      <div class="inputSearch">
        <img
            src="@/assets/icons/search.svg"
            @click="getAllSignature()"
            class="Pointer search"
        />
        <b-form-input
            class="input-busca"
            @change="getAllSignature()"
            v-model="searchComment"
            placeholder="Pesquise uma assinatura"
        ></b-form-input>
    </div>
    <div :class="{'gridListMenu':!isMobile,'gridListMenuMobile':isMobile}">
      <div>
        <button class="createNew" @click="openModalNew">Nova Assinatura</button>
      </div>
    </div>
    </div>
    <br>
      <button class="btnEditMail" @click="openConfigMail">Gerenciar E-mail</button>
    <br>
    <div class="spaceTable" v-if="!loading">
        <b-table :fields="fieldsx" show-empty small stacked="md" :items="AllSignature" :tbody-tr-class="rowClass" :filter-included-fields="filterOn">
          <template #empty>
              <p class="title text-center-empty">{{ emptyText }}</p>
              </template>
              <template #emptyfiltered>
              <p class="title text-center-empty">{{ emptyText }}</p>
          </template>


          <template #cell(id)="row">
              <div class="spaceInfo animationOpacity2">
                <div class="info">{{ row.item.id }}</div>
              </div>
          </template>

          <template #cell(title)="row">
            <div class="spaceInfo animationOpacity2">
              <div class="info">{{ row.item.title }}</div>
            </div>
          </template>

          <template #cell(created_at)="row">
            <div class="spaceInfo animationOpacity2">
              <div class="info">{{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}</div>
            </div>
          </template>

          <template #cell(updated_at)="row">
            <div class="spaceInfo animationOpacity2">
              <div class="info">{{ row.item.updated_at | moment("DD/MM/YYYY HH:mm") }}</div>
            </div>
          </template>

          <template #cell(id)="row">
              <div class="spaceInfo animationOpacity2">
                <div class="flexAction">
                  <div class="deletar" @click="deleteSignature(row.item.id)">Deletar</div>
                  <div class="gerenciar" @click="editAssinatura(row.item)">Gerenciar</div>
                </div>
              </div>
          </template>
      </b-table>
      <div class="paginate">
        <div class="text" @click="toPage('prev')">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 6H1M1 6L6 11M1 6L6 1" stroke="#333333" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Anterior
        </div>
        <div class="text" @click="toPage('next')">
          Próximo
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6L11 6M11 6L6 1M11 6L6 11" stroke="#333333" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div> 
  </div>
  <div class="d-flex justify-content-center mt-5" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
  <b-modal id="modal-new-signature" hide-footer size="lg">
      <div class="spaceModal">
            <div class="titleModal" v-if="idToEdit === null">Criar Assinatura</div>
            <div class="titleModal" v-else>Editar Assinatura</div>
            <div class="spaceSelectPainel">
                <div class="spacePainels">
                    <div class="spaceInputs">
                        <b-form-group label="Nome da Assinatura" label-for="name">
                            <b-form-input
                            v-model="nameSignature"
                            placeholder="Nome da Assinatura"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <!-- <div class="spaceInputs" v-if="idToEdit !== null">
                        <b-form-group label="created_at da Assinatura" label-for="name">
                           <b-form-select v-model="selectedStatus" :options="optionsStatus"></b-form-select>
                        </b-form-group>
                    </div> -->
                    <div class="spaceInputs2" v-if="idToEdit !== null">
                        <b-form-group label="Associar curso" label-for="name">
                          <multiselect placeholder="Selecione um Curso" selectedLabel="" deselectLabel="Pressione Enter" tagPlaceholder="" :close-on-select="false" selectLabel="Pressione Enter" track-by="id" @select="addCourse" @remove="removeCourse" :multiple="true" :taggable="false" :custom-label="customLabelActionMail" v-model="actionCoursesSelected" :options="AllCourses">
                              <template slot="afterList">
                                  <div v-observe-visibility="reachedEndOfList"/>
                              </template>
                          </multiselect>
                        </b-form-group>
                    </div>
                  </div>
                <div class="header">
                    <button class="newPainel" @click="saveSignature">Salvar Assinatura</button>
                </div>
            </div>
        </div>
  </b-modal>
  <b-modal id="modal-mailass" hide-footer size="xl">
    <div class="spaceModalMail">
      <div class="title">Gerenciamento de E-mail</div>
      <b-col cols="12" md="12" class="pt-3 mb-3 animationOpacity2">
        <div class="content-email-total containerWizzardInputs">
            <div id="example" class="content-email-editor">
                <div id="bar">
                    <div class="esconde-logo"></div>
                    <EmailEditor ref="emailEditor2" 
                        v-on:load="editorLoaded"
                        v-on:designUpdated="designUpdated"
                        :designTags="designTags" 
                        :options="options_unlayer" 
                        locale='pt-BR' 
                        :translations='traducaoUnlayer'
                    />
                </div>
            </div>
            <div class="spacebtnEmail animationOpacity2">
                <button @click="cancelaEmail" class="btn-cancelaremail">Cancelar</button>
                <button @click="salvarEmail" class="btn-salvaremail">Salvar Email</button>
            </div>
        </div>
      </b-col>
    </div>
  </b-modal>
  </div>
</template>

<script>
import SignatureService from "@/services/resources/SignatureService";
const serviceSignature = SignatureService.build();
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();
import notify from "@/services/libs/notificacao";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css'
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import traducao_unlayer from '../services/libs/traducao_unlayer.json'
import { EmailEditor } from '../../node_modules/vue-email-editor';
import white from '../assets/unlayer/sample.json';

export default {
  components:{
    Multiselect,
    EmailEditor
  },
  data() {
    return {
      loading:false,
      moderation:true,
      rowClass:'row-table-body',
      emptyText: "Sem Resultados",
      filterOn: [],
      AllSignature:[],
      fieldsx: [
          { key: 'title', label: 'Titulo'},
          { key: 'created_at', label: 'Criado em'},
          { key: 'updated_at', label: 'Atualizado em'},
          { key: 'id', label: 'Ações'},
      ],
      pageActual:1,
      lastPage:1,
      firstPage:1,
      searchComment:'',
      nameSignature:'',
      client:{
        width: 0
      },
      idToEdit:null,
      AllCourses:[],
      actionCoursesSelected:null,
      pageCourse:1,
      lastPageCourse:1,
      selectedStatus: 'published',
      optionsStatus: [
        { value: 'published', text: 'Publicado' },
        { value: 'draft', text: 'Rascunho' },
      ],
      designTags:{
            business_name: "G Digital",
            current_user_name: "G Digital",
            link_botao: "https://google.com.br",
        },
        options_unlayer: {
            mergeTags: [
                {name: "Nome do Cliente", value: "{{nome}}"},
                {name: "Primeiro nome do Cliente", value: "{{primeiro_nome}}"},
                {name: "E-mail do Cliente", value: "{{lead}}"}
            ],
            features: {
                textEditor: {
                    cleanPaste: false
                }
            },
            designTags: {
                business_name: "",
                current_user_name: "",
                link_botao: "https://google.com.br",
            },
        },
        traducaoUnlayer:traducao_unlayer,
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created(){
    window.addEventListener('resize', this.handleResize)
      this.handleResize();
  },
  methods: {
    salvarEmail(){
      this.$refs.emailEditor2.editor.exportHtml(
        (data) => {
          //console.log('exportHtml', data);
          var urlDesign = 'welcomeSignatureEmailDesign';
          var urlHtml = 'welcomeSignatureEmailHtml';

          var dataDesign = {
              id: urlDesign,
              value: JSON.stringify(data.design),
          }
          //console.log(data);
          this.loading = true;
          serviceMeta
          .postID(dataDesign)
          .then((resp) => {
          //console.log("meta email design update", resp);
          this.loading = false;
          })
          .catch((err) => {
          //console.log(err);
          this.loading = false;
          });
                
          var dataHtml = {
              id: urlHtml,
              value:data.html,
          }
          //console.log(data);
          this.loading = true;
          serviceMeta
          .postID(dataHtml)
          .then((resp) => {
          //console.log("meta email design update", resp);
          this.$root.$emit('bv::hide::modal', 'modal-mailass', '#btnShow');
          this.loading = false;
          })
          .catch((err) => {
          //console.log(err);
          this.loading = false;
          });
          }
        )
    },
    cancelaEmail(){
      this.$root.$emit('bv::hide::modal', 'modal-mailass', '#btnShow');
    },
    openConfigMail(){
      this.$root.$emit('bv::show::modal', 'modal-mailass', '#btnShow');
      this.loading = true;
      var keyUrl = 'keys[]=welcomeSignatureEmailDesign';
      serviceMeta
      .search(keyUrl)
      .then((resp) => {
          //console.log("meta email", resp);
          this.$refs.emailEditor2.editor.loadDesign(JSON.parse(resp.welcomeSignatureEmailDesign));
          this.loading = false;
      }).catch((err) => {
          //console.log(err);
          this.loading = false;
      });
    },
    designUpdated(){
        this.$refs.emailEditor2.editor.exportHtml(
            (data) => {
                //console.log('exportHtml', data);
            }
        )
    },
    addTemplateInitial(){
        this.template_selected = white;
    },
    editorLoaded() {
        let email = this.template_selected;
        this.$refs.emailEditor2.editor.loadDesign(email);
    },
    exportHtml() {
        this.$refs.emailEditor2.editor.exportHtml(
            (data) => {
                //console.log('exportHtml', data);
            }
        )
    },
    reachedEndOfList(reached) {
        if (reached) {
          this.addNextPageCourse();
        }
    },
    addNextPageCourse(){
        if(this.lastPageCourse >= this.pageCourse){
            this.pageCourse = this.pageCourse+1;
            this.getAllCourses('push');
        }
    },
    addCourse(selectedOption, id){
      //console.log('to add', selectedOption, id);
      this.loading = true;
      let data = {
        'id':this.idToEdit+'/course/',
        'course_ids': [selectedOption.id]
      }
      serviceSignature
        .postID(data)
        .then((resp) => {
          //console.log("add course assing", resp);
          this.getCoursesToAssing(this.idToEdit);
          notify('sucesso', 'Curso adicionado com Sucesso!');
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          notify('erro', 'Erro ao adicionar Curso da Assinatura!');
          this.loading = false;
        });
    },
    removeCourse(removedOption){
      //console.log('to remove', removedOption);
      this.loading = true;
      let data = {
        'id':this.idToEdit+'/course/delete',
        'course_ids':[removedOption.id]
      }
      serviceSignature
        .postID(data)
        .then((resp) => {
          //console.log("delete course assing", resp);
          notify('sucesso', 'Curso removido com Sucesso!');
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          notify('erro', 'Erro ao deletar Curso da Assinatura!');
          this.loading = false;
        });
    },
    customLabelActionMail ({ title }) {
        return `${title}`
    },
    editAssinatura(data){
      this.idToEdit = data.id;
      this.nameSignature = data.title;
      this.getCoursesToAssing(data.id);
      this.selectedStatus = data.status;
      this.$root.$emit('bv::show::modal', 'modal-new-signature', '#btnShow');
    },
    getCoursesToAssing(id){
      serviceSignature
        .read(id+'/course')
        .then((resp) => {
          //console.log("todos os cursos da assinatura", resp);
          this.actionCoursesSelected = resp;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    saveSignature(){
      this.loading = true;
      if(this.idToEdit === null){
        serviceSignature
        .create({title: this.nameSignature})
        .then((resp) => {
            //console.log("create assinatura", resp);
            this.loading = false;
            this.getAllSignature();
            this.idToEdit = resp.id;
            this.nameSignature = resp.title;
            this.selectedStatus ="published";
            this.getCoursesToAssing(resp.id);
            notify('sucesso', 'Assinatura criada com Sucesso!');
        })
        .catch((err) => {
            //console.log(err);
            this.loading = false;
                            var error = JSON.parse(err.response.data);;
                var msg = '';
                for (var indice in error) {
                    msg += error[indice][0] + "<br>";
                }
                if (msg !== ''){
                    notify('erro', msg);
                }
            this.idToEdit = null;
        });
      }else{
        serviceSignature
        .postID({id: this.idToEdit, title: this.nameSignature, status: this.selectedStatus})
        .then((resp) => {
            //console.log("update assinatura", resp);
            this.loading = false;
            this.$root.$emit('bv::hide::modal', 'modal-new-signature', '#btnShow')
            this.getAllSignature();
            notify('sucesso', 'Assinatura atualizada com Sucesso!');
            this.idToEdit = null;
        })
        .catch((err) => {
            //console.log(err);
            this.loading = false;
                            var error = JSON.parse(err.response.data);;
                var msg = '';
                for (var indice in error) {
                    msg += error[indice][0] + "<br>";
                }
                if (msg !== ''){
                    notify('erro', msg);
                }
            this.idToEdit = null;
        });
      }
    },
    openModalNew(){
      this.nameSignature = '';
      this.idToEdit = null;
      this.actionCoursesSelected = null;
      this.$root.$emit('bv::show::modal', 'modal-new-signature', '#btnShow')
    },
    handleResize() {
        this.client.width = window.innerWidth;
    },
    toPage(action){
      if(action === 'prev'){
        if(this.pageActual !== 1){
          this.pageActual = this.pageActual-1;
        }
      }else{
        if(this.lastPage > this.pageActual){
          this.pageActual = this.pageActual+1;
        }
      }
      this.getAllSignature();
    },
    deleteSignature(id){
      this.loading = true;
      serviceSignature
        .destroy(id)
        .then((resp) => {
          //console.log("delete assinatura", resp);
          this.getAllSignature();
          notify('sucesso', 'Assinatura deletada com Sucesso!');
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          notify('erro', 'Erro ao deletar Assinatura!');
          this.loading = false;
        });
    },
    getAllCourses(action){
      serviceCourse
        .search('page='+this.pageCourse)
        .then((resp) => {
          //console.log("todos os cursos", resp);
          if(action === 'push'){
              let list = resp.data;
              for (let i = 0; i < list.length; i++) {
                  const element = list[i];
                  this.AllCourses.push(element);
              }
          }else{
              this.AllCourses = resp.data;
          }
          this.pageCourse = resp.current_page;
          this.lastPageCourse = resp.last_page;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getAllSignature() {
      this.loading = true;
      if(this.pageActual === null){
        this.pageActual = 1;
      }

      var url = ''
          if(this.searchComment === '' || this.searchComment === null){
          url = 'page='+this.pageActual;
        }else{
          url = 'page='+this.pageActual+'&search_string='+this.searchComment;
        }

        serviceSignature
        .search(url)
        .then((resp) => {
          //console.log("todas as assinaturas", resp);
          var data = resp.data;
          this.pageActual = resp.current_page;
          this.lastPage = resp.last_page;
          this.AllSignature = data;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getAllSignature();
    this.getAllCourses();
  },
};
</script>

<style lang="scss">
.spaceAssinatura .table td {
    border-top: none !important;
    border-bottom: 1px solid #E5E5E5 !important;
}
.spaceModalMail{
  margin-top: -20px;
  padding: 0px 10px;
  #bar{
       iframe{
            min-width: 1024px !important;
            min-height: 425px !important;
       }
       .unlayer-editor{
           min-height: 0px !important;
       }
    }
      .spacebtnEmail{
        margin-top: 20px;
        .btn-salvaremail{
            background: var(--maincolor);
            border-radius: 5px;
            height: 45px;
            width: 245px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #FFFFFF;
            border: none;
            margin-left: 20px;
        }
        .btn-cancelaremail{
            border-radius: 5px;
            height: 45px;
            width: 245px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            border: none;
            background: #f7f7f7;
            color: #81858E;
        }
    }
  .title{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    color: #333333;
    margin-bottom: 30px;
  }
  .btnsGrid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      .spaceBtns{
        margin-bottom: 15px;
    }
    .btnActionEmail{
        background: rgba(33, 51, 210, 0.1);
        border-radius: 5px;
        width: 344.97px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        height: 60px;
        letter-spacing: 0.2px;
        color: #2133D2;
        border: none;
    }
  }
}
.spaceAssinatura {
  width: 100%;
  padding: 0 44px 0 48px;
  padding-top: 70px;
  .btnEditMail{
    width: 231.25px;
    height: 45px;
    background: rgba(33, 51, 210, 0.1);
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #2133D2;
    border:none;
    margin-top: 10px;
  }
  .createNew{
    width: 245.79px;
    height: 55px;
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px var(--maincolortrans);
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
  }
  .textTotalSelected{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #81858E;
    margin-bottom: -40px;
    margin-top: 20px;
  }
  .espacamento-svg-action{
      margin-right: 5px;
      margin-bottom: 3px;
  }
  .btns-actions{
      border:none;
      border: 1px solid #00E4A0;
      box-sizing: border-box;
      border-radius: 3px;
      height: 38px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: #00E4A0;
      margin-right: 20px;
      background-color: #fff0;
      width: 136px;
      margin-top: 30px;
      margin-bottom: -40px;
  }
  .spaceSearch{
    .inputSearch {
        position: relative;
        margin-top: 20px;
        width: 28em;
        top:10px;
        img{
          cursor: pointer;
        }
    }
    .input-busca {
        width: 100%;
        height: 60px;
        background: #ffffff;
        border: solid 1px var(--bordercolor);;
        border-radius: 5px;
        padding: 20px 30px;
        font-family: Montserrat;
        font-weight: normal;
        color: #81858e;
        font-size: 14px;
        transition: 0.3s;
    }
    .input-busca:hover,
    .input-busca:focus {
        box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
    }
    .search {
        position: absolute;
        right: 25px;
        top: 24px;
        width: 14.7px;
        height: 14.7px;
        z-index: 999;
        -webkit-transition: 0.3s;
        transition: 0.3s;
    }
  }
  .activeList {
    color: var(--maincolor) !important;
    transition: 0.3s;
    background: #f9f9f92b;
    border-radius: 3px;
    border: 1px solid var(--maincolor);
    svg{
      path{
        stroke: var(--maincolor) !important
      }
    }
  }
  .gridListMenu{
    display: grid;
    grid-template-columns: 1fr 1em;
    margin-top: -40px;
    position: absolute;
    right: 0;
    .menu{
      cursor: pointer;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        justify-items: start;
        color: var(--fontcolor);
        padding: 7px;
        transition: 0.5s;
        justify-self: center;
    }
  }
  .paginate{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
    margin-top: 40px;
    .text{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #333333;
      cursor: pointer;
    }
    .text:hover{
      color:#00E4A0;
    }
    .text:hover svg path{
      stroke:#00E4A0 !important
    }
  }
  .cancelaBtn{
    margin-right: 20px;
    background: #f7f7f7 !important;
    color: #81858E !important;
  }
  .btnResp {
    background: var(--maincolortrans);
    color: var(--maincolor);
    font-weight: 600;
    font-size: 13px;
    border: none;
    border-radius: 3px;
    padding: 12px 25px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .hide{
    display: none;
  }
  .spaceTable{
    margin-top: 60px;
    .spaceInfo{
      cursor: pointer;
      .flexAction{
        display: flex;
      }
      .info{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #81858E;
      }
      .gerenciar{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 5px;
        letter-spacing: 0.2px;
        color: var(--maincolor);
        margin-right: 15px;
        cursor: pointer;
      }
      .deletar{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 5px;
        letter-spacing: 0.2px;
        color: #FF0C37;
        margin-right: 15px;
        cursor: pointer;
      }
    }
    .spaceComment{
      width: 40em;
      .comment{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #81858E;
      }
      .actions{
        display: flex;
        .btns{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          border: none;
          background: none;
          margin-top: 30px;
          margin-right: 20px;
          text-transform: uppercase;
        }
        .aprove{
          color: #00E4A0;
        }
        .resp{
          color: #81858E;
        }
        .edit{
          color: #81858E;
        }
        .delete{
          color: #FF0C37;
        }
        .removeaprove{
          color: #dfbe06;
        }
      }
    }
    .spaceCreate {
      margin-bottom: 40px;
      .dataCreate{
        display: flex;
        .userDados{
          .name{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            color: var(--fontcolor);
            margin-bottom: 10px;
          }
          .email{
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #81858E;
            margin-bottom: 20px;
          }
        }
        .userPic{
          // background: rgb(0, 0, 0);
          // border: 1px solid #00D6E4;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          margin-right: 20px;
          img{
            width: 100%;
            border-radius: 100%;
          }
        }
      }
      .date{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #81858E;
        margin-left: 60px;
      }
  }
    .table thead th {
      border: none !important;
    }
    .table th, .table td {
      border: none !important;
    }
    .table thead th {
      border: none !important;
    }
    .table td {
                  border-top: none !important;
            border-bottom: 1px solid #E5E5E5 !important;
                    line-height: 30px;


    }
    .table th {
        border: none !important;
        font-family: Montserrat !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        letter-spacing: 0.2px !important;
        color: var(--fontcolor) !important;
    }
    th{
      div{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        color: var(--fontcolor);
        margin-bottom: 15px;
      }
    }
  }
  .spaceActive{
    .title{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
  .headerFlex{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .title{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      color:var(--fontcolor);
    }
    .menu{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
    }
  }
}

#modal-new-signature{
  .spaceInputs2{
    margin-bottom: 30px;
    label{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
      }
  }
  .spaceInputs{
      margin-bottom: 30px;
      select{
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 55px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow:hidden !important;
          resize:none !important;
      }
      input, textarea{
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 55px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
          overflow:hidden !important;
          resize:none !important;
          background: white;
      }
      input:hover, select:hover, textarea:hover, input:focus, select:focus, textarea:focus{
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
      }
      label{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
      }
  }
  .header{
      width: 100%;
      display: flex;
      justify-content: flex-end;
  }
  .newPainel{
      background: var(--maincolor);
      border: 1px solid var(--maincolor);
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 5px;
      height: 55px;
      width: 250px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.2px;
      color: #FFFFFF;
      padding: 0px 15px;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .modal-header {
      border: none !important;
  }
  .spaceModal{
      padding: 5px ​30px 0px 30px;
      margin-top: -20px;
      .logout{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #FF0C37;
          margin-top: 10px;
          cursor: pointer;
      }
      .logout2{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--maincolor);
          margin-top: 10px;
          cursor: pointer;
      }
      .titleModal{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          letter-spacing: 0.2px;
          color: #333333;
      }
      .spaceSelectPainel{
          background: var(--backgroundcolor);
          // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          padding: 20px 5px;
          .lineDiv{
              height: 0.5px;
              background: #EDEDF0;
              width: 100%;
              margin-bottom: 25px;
              margin-top: 25px;
          }
          .flexData{
              display: flex;
              margin-bottom: 20px;
              cursor: pointer;
              .pointer{
              background: rgba(0, 214, 228, 0.1);
              border: 1px solid #00D6E4;
              box-sizing: border-box;
              width: 40px;
              height: 40px;
              border-radius: 100%;
              }
              .title{
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              color: #333333;
              margin-left: 20px;
              line-height: 40px;
              }
          }
      }
  }
}
</style>
